// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { buildRequestMessage } from "../../../components/src/buildRequestMessage.web";

export const configJSON = require("./config");

enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export interface Props {
    navigation: any;
    id: string;
    classes: any;
}

interface S {
    password: string;
    email: string;
    emailError: string;
    passwordError: string;
    snackbar: {
        message: string;
        severity: 'success' | 'info' | 'warning' | 'error' | undefined;
        open: boolean;
    }
}

interface SS {
    id: any;
}

export default class SignInUserController extends BlockComponent<
    Props,
    S,
    SS
> {

    public RequestMessage = {
        loginUser: buildRequestMessage(Method.POST, configJSON),
        Null: undefined as any,
    };

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            email: "",
            password: "",
            emailError: "",
            passwordError: "",
            snackbar: {
                message: '',
                severity: 'info',
                open: false
            }
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Web Event Handling

    handleLoginUserResponse = (response: any) => {
        if (response?.error) {
            const error = response.error;
            this.setState({
                snackbar: {
                    message: error,
                    severity: 'error',
                    open: true
                }
            });
        } else {
            localStorage.setItem("token", response?.meta?.token);
            localStorage.setItem("refresh_token", response?.meta?.refresh_token);

            if (response?.user) {
                const user = {
                    id: response?.user?.id,
                    type: response?.user?.role,
                    attributes: {
                        email: response?.user?.email,
                        first_name: response?.user?.first_name,
                        last_name: response?.user?.last_name,
                        industry_sector: response?.user?.industry_sector,
                        company: response?.user?.company,
                        terms_of_service: response?.user?.terms_of_service,
                        activated: response?.user?.activated,
                        local_authority: response?.user?.local_authority,
                        confirmed: response?.user?.confirmed,
                        avatar: response?.user?.avatar,
                        has_company: response?.user?.has_company,
                        has_districts: response?.user?.has_districts,
                        has_industry_sector: response?.user?.has_industry_sector,
                        is_tutorial_completed: response?.user?.is_tutorial_completed
                    }
                }
                localStorage.setItem("user", JSON.stringify(user));
            }
            if (response.meta.first_time_login) {
                const msg: Message = new Message(
                    getName(MessageEnum.NavigationCreateUserAccount)
                );
                msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(msg);
                return;
            }
            const skipGuideCookie = this.getCookie("skipGuide");
              if (!skipGuideCookie) {
                 this.props.navigation.navigate("Onboardingguide")
                }
            else
                this.props.navigation.navigate("SurveysWeb");
        }
    };

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        if (callID === this.RequestMessage.loginUser.messageId) {
            this.handleLoginUserResponse(response);
        }
    }

    loginUser = (form: any) => {
        this.RequestMessage.loginUser.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.loginUserEndpoint
        );

        this.RequestMessage.loginUser.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(form)
        );

        runEngine.sendMessage(this.RequestMessage.loginUser.id, this.RequestMessage.loginUser);
    };

    getCookie = (name: string) => {
        const cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
          const [key, value] = cookies[i].split("=");
          if (key === name) {
            return value;
          }
        }
        return null;
    };

    handleClickLoginButton = () => {
        const { email, password } = this.state;
        const isEmail = (val: string) => {
            let regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!regEmail.test(val)) {
                return 'Invalid email address';
            }
        }
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{10,}$/;
        const passwordError = passwordRegex.test(password);
        const emailError = isEmail(email);

        if (email === '' && password === '') {
            this.setState({ emailError: 'Email address cannot be blank' })
            this.setState({ passwordError: 'Password cannot be blank' })
            return;
        }
        if (!emailError && password === '') {
            this.setState({ passwordError: 'Password cannot be blank' })
            return;
        }
        if (passwordError && email === '') {
            this.setState({ emailError: 'Email address cannot be blank' })
            return;
        }
        if (emailError) {
            this.setState({ emailError });
            return;
        }
        if (!passwordError) {
            this.setState({ passwordError: configJSON.errorInvalidPasswordMessage });
            return;
        }
        this.loginUser({ email, password })
    }

    resetLoginError = (val: string) => {
        if (val === 'email') {
            this.setState({ emailError: '' });
            return;
        }
        this.setState({ passwordError: '' });
    }

    handleChangePassword = (e: any) => {
        this.setState({ password: e.target.value });
        this.resetLoginError("password");
    }

    handleChangeEmail = (e: any) => {
        const emailRegex = /^[a-zA-Z0-9.@+-_~]*$/;
        if (emailRegex.test(e.target.value)) {
            this.setState({ email: e.target.value });
        } 
        this.resetLoginError("email");
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbar: {
                message: '',
                severity: 'info',
                open: false
            }
        });
    }

    handleEnterPressed = (e: any) => {
        if(e.key === 'Enter') {
            this.handleClickLoginButton()
        }
    }
    handleUserNavigation = () => {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationForgotPasswordMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationForgotData), true)
        this.send(msg);
    }

}
// Customizable Area End