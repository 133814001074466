export const onBoardingOne = require("../assets/Onboarding_One.png");
export const onBoardingTwo = require("../assets/Onboarding_Two.png");
export const onboardingThree = require("../assets/Onboarding_Three.png");
export const onboardingFour = require("../assets/Onboarding_Four.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const onBoarding1 = require("../assets/onboarding_1.png");
export const onBoarding2 = require("../assets/onboarding_2.png");
export const iphone1 = require("../assets/iphone1.png");

