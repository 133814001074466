import React from "react";
// Customizable Area Start
import { Box, Button, Container, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
// Customizable Area End

import OnboardingguideController, {
  Props,
  configJSON
} from "./OnboardingguideController";

export default class Onboardingguide extends OnboardingguideController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <MainContainer >
        <Paper className="card">
          <img src={this.cards[this.state.currentStep]?.question} alt="Question Image"
            className="cardImage" />
        </Paper>

        <Box className='cardBottom'>
          <Typography variant="h5" className='guideTitle'>
            {this.cards[this.state.currentStep]?.guideTitle}
          </Typography>
          <Typography variant="body2" className='description'>
            {this.cards[this.state.currentStep]?.guideDescription}
          </Typography>

          <Box className='pagination'>
            {this.cards.map((_, index) => (
              <div
                data-test-id={`dot-${index}`}
                key={index}
                onClick={() => this.handleDots(index)}
                className={`dot ${index === this.state.currentStep ? 'activeDot' : ''}`}
              />
            ))}
          </Box>

          <Grid container spacing={2} className='buttonContainer'>
            <Grid item xs={6}>
              <Button
                data-test-id="skip-btn"
                fullWidth
                variant="outlined"
                className='skipButton'
                onClick={this.handleSkip}
              >
                Skip
              </Button>
            </Grid>
            <Box>
              <Button
                data-test-id="next-btn"
                fullWidth
                variant="contained"
                className='nextButton'
                onClick={this.handleNext}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Box>

      </MainContainer>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Container)({
  width: "100%",
  "& .questionNumber": {
    marginBottom: "10px",
    fontWeight: "bold"
  },
  "& .card": {
    padding: "5px",
    borderRadius: "5px",
    width: "326px",
    maxWidth: "300px",
    height: "225px",
    margin: "0 auto",
    overflow: "hidden"
  },
  "& .cardImage": {
    width: "100%",
    height: "auto",
    borderRadius: "5px",
    objectFit: "cover",
  },
  "& .cardBottom": {
    padding: "10px",
    width: "462px",
    maxWidth: "400px",
    margin: "0 auto",
  },
  "& .inputBox": {
    marginBottom: "10px",
  },
  "& .guideTitle": {
    fontWeight: "700",
    marginBottom: "10px",
    textAlign: "center",
  },
  "& .description": {
    fontWeight: "400",
    textAlign: "center",
    marginBottom: "10px",
  },
  '& .pagination': {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  '& .dot': {
    width: "9.64px",
    height: "9.64px",
    borderRadius: "50%",
    margin: "2px",
    border: "1px solid #202020",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  '& .activeDot': {
    width: "9.64px",
    height: "9.64px",
    backgroundColor: "#202020",
  },
  "& .buttonContainer": {
    display: "flex",
    justifyContent: "space-between",
  },
  '& .skipButton': {
    flex: 1,
    width: "81px",
    height: "40px",
    marginRight: "5px",
  },
  '& .nextButton': {
    flex: 1,
    width: "81px",
    height: "40px",
    marginLeft: "5px",
    backgroundColor: "#202020",
    color: "#FFFFFF",
    alignSelf: "right"
  },
})
// Customizable Area End
