import React from "react";
// Customizable Area Start

// Customizable Area End

import SchedulingController, {
  Props
  // Customizable Area Start
  // Customizable Area End
} from "./SchedulingController";
import { Container, styled } from "@material-ui/core";
import Calender from "../../../components/src/Calender/Calender.web";

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <MainContainer>
        <Calender />
      </MainContainer>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Container)({
  width: "100%",
  color: "#616161",
  padding: "20px",
  backgroundColor:"#FFFFFF",
  fontFamily: "'Raleway', sans-serif", 
});
// Customizable Area End
