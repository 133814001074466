import React from "react";
// Customizable Area Start
import { Box, Button, Container, Dialog, Grid, styled, TextField, Typography } from "@material-ui/core";
import { fluent } from "./assets";
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON
} from "./CustomisableusersubscriptionsController";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const card = [
      {
        title: "Advance",
        body: "Lorem ipsum dolor sit amet, conse ctetur adipiscing Features include Lorem ipsum dolor si ",
        price: "$42/month",
        subscribed: false
      },
      {
        title: "Advance",
        body: "Lorem ipsum dolor sit amet, conse ctetur adipiscing Features include Lorem ipsum dolor si ",
        price: "$32/month",
        subscribed: true
      },
      {
        title: "Advance",
        body: "Lorem ipsum dolor sit amet, conse ctetur adipiscing Features include Lorem ipsum dolor si ",
        price: "$42/month",
        subscribed: false
      },
    ]
    return (
      <MainContainer>
        <Box style={{ display: (this.state.changePayment == 1) ? "" : "none" }}>
          <Box className="profile">
            <Box className="subscriptionHeader">
              <img src={fluent} alt="Logo" className="logo" />
              <Typography
                variant="h6"
                style={{
                  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                Current Plan
              </Typography>
            </Box>
            <Box className="subscriptionDetails">
              <Typography variant="h6">
                Your Subscription
              </Typography>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </Typography>
              <Typography variant="body1">
                $32/month
              </Typography>
              <Box className="buttonsContainer">
                <button className="secondaryButton" data-test-id="cancel-btn" onClick={() => { this.setState({ isOpen: true }) }}>Cancel</button>
                <button className="primaryButton">Update</button>
              </Box>
            </Box>
          </Box>
          <Box className="profile">
            <Typography variant="body1">
              Your Billing Details
            </Typography>
            <Typography variant="body1">
              Credit Card Number
            </Typography>
            <input
              type="text"
              className="creditCardInput"
              value="xxxx xxxx xxxx 8087"
              readOnly
            />
          </Box>
        </Box>

        <Box
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: 2,
            display: (this.state.changePayment == 2) ? "" : "none"
          }}
        >
          <Box className="card-title">
            <Typography variant="h6" gutterBottom>
              Billing →
            </Typography>
            <Typography
              variant="h6"
              style={{
                background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Change Subscription Type
            </Typography>
          </Box>

          <Box style={{ padding: "10px", display: "flex", gap: "5px" }} >
            {card.map((val: any, index: number) => {
              return <Box key={index} className="profile">
                <Box className="subscriptionHeader">
                  <img src={fluent} alt="Logo" className="logo" />
                </Box>
                <Box className="subscriptionDetails">
                  <Typography variant="h6">
                    {val.title}
                  </Typography>
                  <Typography variant="body1">
                    {val.body}
                  </Typography>
                  <Typography variant="body1">
                    {val.price}
                  </Typography>
                  <Box className="buttonsContainer">
                    <button className="secondaryButton" data-test-id="curr-sub-btn" style={{ display: (val.subscribed) ? "" : "none" }} onClick={() => { this.setState({ isOpen: true }) }}>Current Subscription</button>
                    <button style={{ display: (val.subscribed) ? "none" : "" }} data-test-id="sub-btn" className="primaryButton" onClick={() => this.setState({ changePayment: 3 })}>Select</button>
                  </Box>
                </Box>
              </Box>
            })}
          </Box>
        </Box>

        <Box
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: 8,
            padding: 20,
            margin: "20px auto",
            display: (this.state.changePayment == 3) ? "" : "none"
          }}
        >
          <Box className="card-title">
            <Typography variant="h6" gutterBottom>
              Billing →
            </Typography>
            <Typography
              variant="h6"
              style={{
                background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Change Subscription Type
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box style={{ padding: 10 }}>
                <Typography
                  variant="subtitle1"
                  component="label"
                  style={{ display: "block", marginBottom: 5, color: "#333333" }}
                >
                  Cardholder Name
                </Typography>
                <TextField
                  fullWidth
                  defaultValue="Sabrina"
                  variant="outlined"
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box style={{ padding: 10 }}>
                <Typography
                  variant="subtitle1"
                  component="label"
                  style={{ display: "block", marginBottom: 5, color: "#333333" }}
                >
                  Credit Card Number
                </Typography>
                <TextField
                  fullWidth
                  defaultValue="xxxx xxxx xxxx 8087"
                  variant="outlined"
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box style={{ padding: 10 }}>
                <Typography
                  variant="subtitle1"
                  component="label"
                  style={{ display: "block", marginBottom: 5, color: "#333333" }}
                >
                  Expiry Date
                </Typography>
                <TextField
                  fullWidth
                  type="month"
                  defaultValue="2025-01"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box style={{ padding: 10 }}>
                <Typography
                  variant="subtitle1"
                  component="label"
                  style={{ display: "block", marginBottom: 5, color: "#333333" }}
                >
                  CVV
                </Typography>
                <TextField
                  fullWidth
                  defaultValue="XXX"
                  variant="outlined"
                  InputProps={{
                    inputMode: "numeric",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Modaldialog className="modal" open={this.state.isOpen}>
          <div className="modalBody">
            <div>
              <Typography style={{ marginBottom: "20px" }} className="modalHeader">Are you sure you want to cancel?</Typography>
            </div>
            <div>
              <Typography variant="caption" className="question">
                You will lose access to your subscription at the end of the current billing period.
              </Typography>
            </div>
            <div className="buttons">
              <Button className="primaryButton">Yes</Button>
              <Button data-test-id="modal-btn" onClick={() => { this.setState({ isOpen: false }) }} className="secondaryButton">No</Button>
            </div>
          </div>
        </Modaldialog>
        <Box className="bottom-btn-box">
          <Button className="primaryButtonPayment" style={{ display: (this.state.changePayment == 1) ? "" : "none" }} onClick={() => { this.setState({ changePayment: 2 }) }}>Change Payment Method</Button>
          <Button className="primaryButtonSave" style={{ display: (this.state.changePayment > 1) ? "" : "none" }}>Save Changes</Button>
        </Box>
      </MainContainer >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Container)({
  width: "100%",
  color: "#000000",
  padding: "20px",

  "& .profile": {
    borderRadius: "12px",
    border: "1px solid #E7E7E7",
    background: "#FFFFFF",
    width: "100%",
    maxWidth: "400px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    textAlign: "center",
    marginBottom: "16px",
  },

  "& .subscriptionHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },

  "& .logo": {
    width: "30px",
    height: "30px",
  },

  "& .currentPlan": {
    fontWeight: "bold",
    color: "#1C386D",
    fontSize: "14px",
  },

  "& h2": {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "8px",
  },

  "& p": {
    fontSize: "14px",
    color: "#666666",
    marginBottom: "16px",
  },

  "& .price": {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333333",
    marginBottom: "16px",
  },

  "& .buttonsContainer": {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "16px",
  },

  "& .secondaryButton": {
    backgroundColor: "#FFFFFF",
    color: "#1C386D",
    border: "1px solid #1C386D",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "120px",
  },

  "& .primaryButton": {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "120px",
  },
  "& .primaryButtonPayment": {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  "& .primaryButtonSave": {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "10px"
  },
  "& .cancelButton": {
    backgroundColor: "#F5F5F5",
    color: "#555",
    border: "1px solid #E7E7E7",
    borderRadius: "4px",
    padding: "8px 16px",
    cursor: "pointer",
  },

  "& .updateButton": {
    backgroundColor: "#202020",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    cursor: "pointer",
  },

  "& .billingDetails": {
    width: "100%",
    maxWidth: "400px",
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    border: "1px solid #E7E7E7",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },

  "& .inputContainer": {
    marginBottom: "16px",
  },

  "& .creditCardInput": {
    width: "100%",
    padding: "12px",
    border: "1px solid #E7E7E7",
    borderRadius: "4px",
    marginTop: "8px",
    boxSizing: "border-box",
  },
  "& .bottom-btn-box": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .card-title": {
    borderBottom: "1px solid #E7E7E7",
    padding: "10px",
    display: "flex"
  },
  "& .changePaymentButton": {
    width: "100%",
    backgroundColor: "#202020",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "12px 16px",
    cursor: "pointer",
    textAlign: "center",
    marginTop: "16px",
  },
});

const Modaldialog = styled(Dialog)({
  borderRadius: '16px',
  color: "#000000",
  "& .modalBody": {
    borderRadius: '16px',
    background: '#FFF',
    padding: '20px',
    color: "#000000",
    width: "400px"
  },
  "& .buttons": {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center'
  },
  "& .primaryButton": {
    padding: '18px 32px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none' as const,
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    borderRadius: '10px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    flex: '1 1 0px',
    marginTop: '30px',
    fontFamily: 'aeonik-regular',
  },
  "& .secondaryButton": {
    alignItems: 'center',
    textTransform: 'none' as const,
    padding: '18px 32px',
    justifyContent: 'center',
    border: 'none',
    fontSize: '16px',
    fontWeight: 700,
    background: 'none',
    color: '#333',
    fontStyle: 'normal',
    lineHeight: 'normal',
    flex: '1 1 0px',
    fontFamily: 'aeonik-regular',
  },
})
// Customizable Area End
