// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { buildRequestMessage } from "../../../components/src/buildRequestMessage.web";
import React from "react";

export const configJSON = require("./config");

enum Method {
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
  POST="POST",
}

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  intialProfileIcon?: File | null;
  isChange:boolean,
  indusName: string,
  districtName:string,
  activeItem?: number | null;
  selectedId: number ;
  userId:number;
  isImageValid: boolean,
  profilEditor:boolean,
  zoomLevel:number,
  croppedImageFile:any,
  editorRef:any,
  fullProfileImage:any,
  avtar: any,
  imageChanged: boolean;
  errorMessage: any,
  intialUser: {
    first_name: string;
    last_name: string;
    avatar: string | null;
    id: number | null;
    email?: string;
    company?: string;
    local_authority?: string;
    industry_sector?: string;
    industries?: any[];
    districts?: any[];
    district_id?: string;
    industry_sector_id?: string;
    has_company: boolean;
    has_districts: boolean;
    has_industry_sector: boolean;
  };
  user: {
    first_name: string;
    last_name: string;
    avatar: string | null;
    id: number | null;
    email?: string;
    company?: string;
    local_authority?: string;
    industry_sector?: string;
    industries?: any[];
    districts?: any[];
    district_id?: string;
    industry_sector_id?: string;
    has_company: boolean;
    has_districts: boolean;
    has_industry_sector: boolean;
  };
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    showOldPassword: boolean;
    showNewPassword:boolean;
    showConfirmPassword:boolean;
  snackbar: {
    open: boolean;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    message: string;
  };
  signOutConfirmationVisible: boolean;
  changepasswordVisible:boolean;
  subscriptionPlan:boolean;
  profileIcon?: File | null;
  deleteConfirmationVisible: boolean;
  profileIconHasChanged: boolean;
  password: string;
  initials: string;
  termsAndConditionDialogVisible: boolean;
  isTermsAndConditionActive: boolean;
  termsData: {
    id: number | null,
    description: string,
  },
  privacyData: {
    id: number | null,
    description: string,
  },
  currentShowingItem:number,
  selectedDistrictUserID:number | null,
  selectedIndustryUserID:number | null,
}

interface SS {
  id: any;
}

export default class UserSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  postchangepassword:string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        showOldPassword: false,
        showNewPassword:false,
        showConfirmPassword:false,
      errorMessage: null,
      isImageValid: true,
      imageChanged: false,
      avtar: null,
      profilEditor:false,
      zoomLevel:1,
      croppedImageFile:null,
      editorRef:React.createRef(),
      fullProfileImage:null,
      intialProfileIcon:null,
      isChange:false,
      indusName: '',
      districtName:'',
      activeItem: 1,
      selectedId: 1,
      userId:1,
      intialUser : {
        first_name: '',
        last_name: '',
        avatar: null,
        id: null,
        email: '',
        company: '',
        local_authority: '',
        industry_sector: '',
        industries: [],
        districts: [],
        district_id: '',
        industry_sector_id: '',
        has_company: false,
        has_districts: false,
        has_industry_sector: false,
      },
      user : {
        first_name: '',
        last_name: '',
        avatar: null,
        id: null,
        email: '',
        company: '',
        local_authority: '',
        industry_sector: '',
        industries: [],
        districts: [],
        district_id: '',
        industry_sector_id: '',
        has_company: false,
        has_districts: false,
        has_industry_sector: false,
      },
      snackbar: {
        open: false,
        severity: 'info',
        message: ''
      },
      signOutConfirmationVisible: false,
      changepasswordVisible:false,
      subscriptionPlan:false,
      password:'',
      profileIcon: null,
      deleteConfirmationVisible: false,
      profileIconHasChanged: false,
      initials: "",
      termsAndConditionDialogVisible: false,
      isTermsAndConditionActive: false,
      termsData: {
        id: null,
        description: '',
      },
      privacyData: {
        id: null,
        description: '',
      },
      currentShowingItem:1,
      selectedDistrictUserID:null,
      selectedIndustryUserID:null,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      switch (callID) {
        case this.RequestMessage.updateUserData.messageId:
          this.updateUserResponse(response);
          break;
        case  this.postchangepassword: {
              this.receivechangePassword(response);
          }
        case this.RequestMessage.getUserData.messageId:
          if (response) {
            this.getUserResponse(response);
          }
          break;
        case this.RequestMessage.getTermsAndConditions.messageId:
          if (response.errors === undefined) {
            this.receiveTermsAndCondition(response);
          }
          break;
        case this.RequestMessage.getPrivacyPolicy.messageId:
          if (response.errors === undefined) {
            this.receivePrivacyPolicy(response);
          }
          break;
          case this.RequestMessage.changePassword.messageId:
          if (response.errors === undefined) {
            this.receivechangePassword(response);
          }
          break;
        case this.RequestMessage.deleteUserAccount.messageId:
          if (response.error === undefined) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            this.props.navigation.navigate('AuthBlock');
          } else {
            this.setState({
              snackbar: {
                message: response.error,
                open: true,
                severity: 'error'
              }
            });
          }
      }
    }
  }

  getHeader = () => {
    const user = window.localStorage.getItem('user');
    const userId = (user && user !== "undefined") ? JSON.parse(user).id : null;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
      id: userId,
    };
    return JSON.stringify(header);
  }

  buildRequest = (method: any) => {
    const requestMessageForDeleteUserAccount = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessageForDeleteUserAccount.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeader()
    );

    requestMessageForDeleteUserAccount.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.APIBaseURL
    );
    requestMessageForDeleteUserAccount.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessageForDeleteUserAccount;
  };

  public RequestMessage = {
    updateUserData: buildRequestMessage(Method.PUT, configJSON),
    getUserData: buildRequestMessage(Method.GET, configJSON),
    deleteUserAccount: this.buildRequest(Method.DELETE),
    getTermsAndConditions: buildRequestMessage(Method.GET, configJSON),
    getPrivacyPolicy: buildRequestMessage(Method.GET, configJSON),
    changePassword: buildRequestMessage(Method.POST, configJSON),
    Null: undefined as any,
  };

  async componentDidMount() {
    await Promise.all([
      this.getUser(),
      this.getTermsAndConditons(),
      this.getPrivacyPolicy(),
    ]);
  }

  navigateToAuthBlock = (response: any) => {
    if (response?.errors?.message === "Authentication token invalid") {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationAuthBlockMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }

  handleClickShowOldPassword = () => {
    this.setState({ showOldPassword: !this.state.showOldPassword });
  }
  handleClickShowNewPassword = () => {
    this.setState({showNewPassword: !this.state.showNewPassword});
  }
  handleClickShowConfirmPassword = () => {
    this.setState({showConfirmPassword:!this.state.showConfirmPassword})
  }

  showProfileEditor = () => {
    this.setState({profilEditor:true});
  }
  handleZoomChange = (event:any, newValue:any) => {
    this.setState({ zoomLevel: newValue });
  };
  handleProfileEditorClose = () => {
    this.setState({profilEditor:false});
  }
  handleApplyProfileEditor = async () => {
    if (this.state.editorRef.current) {
      const canvas = this.state.editorRef.current.getImageScaledToCanvas();
        // Function to generate a random string
        const generateRandomString = (length=5) => {
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let result = '';
          for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
          }
          return result;
        };
        const randomFileName = generateRandomString(10); // Adjust the length as needed
      canvas.toBlob((blob: any) => {
        if (blob) {
          const file = new File([blob], `${randomFileName}.png`, { type: 'image/jpeg' });
          this.setState({
            user: {
              ...this.state.user,
              avatar: URL.createObjectURL(blob)
            },
            profilEditor: false
          })
          this.setState({ croppedImageFile: file });
          this.setState({imageChanged:true},() => this.checkForChanges());
        }
      }, 'image/png');
      
    }
  };

  getUserResponse = (response: any) => {
    this.navigateToAuthBlock(response);
    if (!response?.error) {
      const industry_sector_id = response?.user?.selected_industry_user || null;
      const district_id = response?.user?.selected_district_user || null;
      this.setState({indusName:response?.user?.industry_name});
      this.setState({districtName:response?.user?.district_name});
      this.setState({fullProfileImage:response.user.avatar.url||null});      
      const user = {
        first_name: response.user.first_name,
        last_name: response.user.last_name,
        avatar: response.user.avatar.url,
        id: response.user.id,
        email: response.user.email,
        company: response.user.company || "",
        local_authority: response.user.local_authority,
        industry_sector: response.user.industry_sector,
        district_id,
        industry_sector_id,
        industries: response.industries,                // Sorting is removed to reflect the order in which data is received from the API. This is intentional to beacuse in frotend there is inconsitency so the sorting is done by backend	
        districts: response.districts,                // Sorting is removed to reflect the order in which data is received from the API. This is intentional to beacuse in frotend there is inconsitency so the sorting is done by backend	
        has_company: response.has_company,
        has_districts: response.has_districts,
        has_industry_sector: response.has_industry_sector,
      };
      const initials =
        (user?.first_name?.charAt(0)?.toUpperCase() || '-') +
        (user?.last_name?.charAt(0)?.toUpperCase() || '-');

      this.setState({
        user,
        profileIcon: response.user.avatar.url,
        initials,
      });
      this.setState({intialUser:user,intialProfileIcon:response.user.avatar.url});
      const localUser = localStorage.getItem('user');

      if (user) {
        const userObj = JSON.parse(localUser || "{}");
        const newUser = {
          ...userObj,
          attributes: {
            ...userObj.attributes,
            first_name: response?.first_name,
            last_name: response?.last_name,
            avatar: {url: response?.avatar?.url}
          }
        }
        localStorage.setItem("user", JSON.stringify(newUser));
      }
    }
  }
  removeProfilePicture = (ev: any) => {
    ev.preventDefault()
    ev.stopPropagation()
    this.setState({
      user: {
        ...this.state.user,
        avatar: null,
      },
      imageChanged: true,
      avtar: null,
      croppedImageFile:null,
    },this.checkForChanges);
    
  }
  handleImageChange = (event: any) => {
    const file = event.target.files?.[0];
    this.setState({
      avtar: file,
      errorMessage: '',
      imageChanged: true,
      croppedImageFile:file,
    },this.checkForChanges);

    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];

      if (fileSizeMB <= 5 && supportedFormats.includes(file.type)) {
        this.setState({fullProfileImage:URL.createObjectURL(file)});
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            avatar: URL.createObjectURL(file),
          },
          isImageValid: true,
          profilEditor: true,
          profileIconHasChanged: true ,
        }));
      } else {
        this.setState({
          isImageValid: false,
          errorMessage: 'Invalid file format or size. Please select a valid image (PNG, JPEG, GIF) within 5MB.',
        });
      }
    }
  };
  checkForChanges = () => {
    const lastNameChanged = this.state.user.last_name !== this.state.intialUser.last_name;
    const firstNameChanged = this.state.user.first_name !== this.state.intialUser.first_name;
    const profilePhotoChanged = this.state.profileIconHasChanged||this.state.imageChanged;
    const companyNameChanged = this.state.user.company !== this.state.intialUser.company;
    const districtIdChanged = Number(this.state.user.district_id ) !== Number(this.state.intialUser.district_id);
    const industrySectorChanged = Number(this.state.user.industry_sector_id) !== Number(this.state.intialUser.industry_sector_id);
    if(firstNameChanged || lastNameChanged || profilePhotoChanged || companyNameChanged || districtIdChanged || industrySectorChanged)
    {
      this.setState({isChange:true})
    }else{
      this.setState({isChange:false});
    }
  }
  updateUserResponse = (response: any) => {
    this.setState({isChange:false})
    if (!response?.errors) {
      const industry_sector_id = response?.user?.selected_industry_user || null;
      const district_id = response?.user?.selected_district_user || null;
      const user = {
        first_name: response.user.first_name,
        last_name: response.user.last_name,
        avatar: response.user.avatar.url,
        company: response.user.company || "",
        local_authority: response.user.local_authority,
        industry_sector: response.user.industry_sector,
        district_id,
        industry_sector_id,
      };
      const initials =
        (user?.first_name?.charAt(0)?.toUpperCase() || '-') +
        (user?.last_name?.charAt(0)?.toUpperCase() || '-');

      this.setState({
        user: {
          ...this.state.user,
          ...user,
        },
        initials,
        profileIcon: response.user.avatar.url,
        snackbar: {
          message: 'Profile updated successfully',
          open: true,
          severity: 'success'
        },
        zoomLevel:1,
      });

      const localUser = localStorage.getItem('user');

      if (user) {
        const userObj = JSON.parse(localUser || "{}");
        const newUser = {
          ...userObj,
          attributes: {
            ...userObj.attributes,
            first_name: response?.first_name,
            last_name: response?.last_name,
            avatar: {url: response?.avatar?.url}
          }
        }
        localStorage.setItem("user", JSON.stringify(newUser));
      }
      if (window) {
        window.dispatchEvent( new Event('storage'))
      }


      // if (this.state.profileIconHasChanged) {
      //   window.location.reload();
      // }
      this.getUser();
    } else {
      this.setState({
        snackbar: {
          message: response.errors[0],
          open: true,
          severity: 'error'
        }
      });
    }
  }

  getUser = () => {
    const user = localStorage.getItem('user');
    const userId = (user && user !== "undefined") ? JSON.parse(user).id : null;
    this.setState({userId:userId})

    this.RequestMessage.getUserData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserEndPoint}?id=${userId}`
    );

    runEngine.sendMessage(this.RequestMessage.getUserData.id, this.RequestMessage.getUserData);
  };

  updateUser = (formData: any) => {
    const user = localStorage.getItem('user');
    const userId = (user && user !== "undefined") ? JSON.parse(user).id : null;
    if(this.state.imageChanged) {
      formData.append("avatar",this.state.croppedImageFile);
    }
    this.RequestMessage.updateUserData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserEndPoint}?id=${userId}`
    );

    this.RequestMessage.updateUserData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(this.RequestMessage.updateUserData.id, this.RequestMessage.updateUserData);
  };

  deleteUserAccount = () => {
    this.RequestMessage.deleteUserAccount.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserAccountEndPoint + '?password=' + this.state.password
    );

    this.RequestMessage.deleteUserAccount.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      {}
    );

    runEngine.sendMessage(this.RequestMessage.deleteUserAccount.id, this.RequestMessage.deleteUserAccount);
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        message: '',
        open: false,
        severity: 'info'
      }
    })
  }

  handleSave = async () => {
    const formdata:any = new FormData();
    if (this.state.profileIcon instanceof File || this.state.profileIcon === null) {
      formdata.append("avatar", this.state.profileIcon);
      // this.setState({ profileIconHasChanged: true });
    }

    const {
      first_name,
      last_name,
      company,
      district_id,
      industry_sector_id,
      id,
    } = this.state.user;
    if (Number(this.state.user.district_id) !== Number(this.state.intialUser.district_id)) {
      formdata.append("district_id", this.state.selectedDistrictUserID);
      district_id && formdata.append("selected_district_user", district_id);
      formdata.append('district_name', this.state.districtName || this.state.user.district_id);
    }
    // only sharing the industry details in request body if user made changes init 
    if (Number(this.state.user.industry_sector_id) !== Number(this.state.intialUser.industry_sector_id)) {
      formdata.append("industry_sector_id", this.state.selectedIndustryUserID);
      industry_sector_id && formdata.append("selected_industry_user", industry_sector_id);
      formdata.append('industry_name', this.state.indusName);
    }
    formdata.append("first_name", first_name);
    formdata.append("last_name", last_name);
    formdata.append("company", company);
    formdata.append("id", id);
    this.updateUser(formdata);
  }

  handleDeleteAccount = () => {
    this.setState({ deleteConfirmationVisible: true });
  }

  handleDeleteConfirmationClose = () => {
    this.setState({ deleteConfirmationVisible: false });
    this.setState({password:''});
  }

  handleDeleteAccountSave = () => {
    if (this.state.password === '') {
      this.setState({
        snackbar: {
          message: 'Please enter your password',
          open: true,
          severity: 'error'
        }
      });
      return;
    }

    this.deleteUserAccount();
  }

  onItemHover = (id: number) => {
    // this.setState({ activeItem: this.state.selectedId });
  }

  onMouseLeave = () => {
    // this.setState({ activeItem: this.state.selectedId });
  }

  onItemClick = (selectedId: number): void => {
    this.setState({ selectedId });
    this.setState({activeItem:selectedId});
    if (selectedId === 1) {
      this.setState({currentShowingItem:1});
      this.setState({ changepasswordVisible: false,subscriptionPlan:false });
    }
    if (selectedId === 2) {
      console.log("2")
      this.setState({currentShowingItem:2});
      this.setState({ changepasswordVisible: true,subscriptionPlan:false  });
    }
    if (selectedId === 3) {
      console.log("3")
      this.setState({ signOutConfirmationVisible: true,subscriptionPlan:false });
    }
    if (selectedId === 4) {
      console.log("34")
      this.setState({currentShowingItem:4});
      this.setState({ subscriptionPlan: true,changepasswordVisible:false,signOutConfirmationVisible:false });
    }
  }

  handlePasswordChange = (event: any) => {
    const password = event.target.value;
    this.setState({ password });
  }

  handlePasswordChanges = async () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const passwordCriteria =/^(?!^\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s$).{10,}$/;
    const body = {
      id:this.state.userId,
      current_password:oldPassword,
      new_password:newPassword,
      new_password_confirmation: confirmPassword,  
    }
    if (!passwordCriteria.test(newPassword)) {
      this.setState({
        snackbar: {
          message:'Password must contain 10 characters, 1 special character, 1 uppercase letter, 1 lowercase letter and one number',
          open: true,
          severity: 'error'
        }
      });
     return;
    }
    else if (newPassword !== confirmPassword) {
      this.setState({
        snackbar: {
          message:'New Password and confirm password do not match',
          open: true,
          severity: 'error'
        }
      });
     return;
    }
    else if (oldPassword === confirmPassword) {
      this.setState({
        snackbar: {
          message:'Old Password and New password is Same',
          open: true,
          severity: 'error'
        }
      });
     return;
    }
    // API call now for change the password
    else {
      this.sendResetPasswordRequest(body);
    }
   };
  
  sendResetPasswordRequest = (body: any) => {
    const userId=this.state.userId;
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": 'application/json',
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_user/users/change_password?id=${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.postchangepassword = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }),this.checkForChanges);
  };
  getIndusName = (industry: any) => {
    this.setState({ indusName: industry.name })
    this.setState({selectedIndustryUserID:industry.id});
  }
  getDistName = (district:any) => {
    this.setState({districtName:district.name});
    this.setState({selectedDistrictUserID:district.id})
  }

  onSignOutClick = () => {
    this.setState({ signOutConfirmationVisible: false });
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.props.navigation.navigate('AuthBlock');
  };

  onSignOutDialogClose = () => {
    this.setState({ signOutConfirmationVisible: false });
    this.setState({activeItem:this.state.currentShowingItem});
    this.setState({selectedId:this.state.currentShowingItem});
  };

  renderTermsAndPrivacyData = () => {
    if (this.state.isTermsAndConditionActive) {
      return {
        title: "Terms and Conditions",
        data: this.state.termsData
      }
    }
    return {
      title: "Privacy Policy",
      data: this.state.privacyData
    }
  }

  handleOpenTermsAndConditionDialog = () => {
    this.setState({ termsAndConditionDialogVisible: true, isTermsAndConditionActive: true });
  }

  handleOpenPrivacyPolicyDialog = () => {
    this.setState({ termsAndConditionDialogVisible: true, isTermsAndConditionActive: false });
  }

  handleCloseTermsAndCondtionDioalg = () => {
    this.setState({ termsAndConditionDialogVisible: false });
  }

  termsAndConditionRecive(responseJson: any) {
    if (responseJson) {
      const { id, description } = responseJson;
      this.setState({
        termsData: {
          id, description
        }
      });
    }
  }

  privacyPolicyRecive(responseJson: any) {
    if (responseJson) {
      const { id, description } = responseJson;
      this.setState({
        privacyData: {
          id, description
        }
      });
    }
  }

  getTermsAndConditons = () => {
    this.RequestMessage.getTermsAndConditions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditions
    );

    runEngine.sendMessage(this.RequestMessage.getTermsAndConditions.id, this.RequestMessage.getTermsAndConditions);
  };

  getPrivacyPolicy = () => {
    this.RequestMessage.getPrivacyPolicy.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicy
    );

    runEngine.sendMessage(this.RequestMessage.getPrivacyPolicy.id, this.RequestMessage.getPrivacyPolicy);
  };

  receiveTermsAndCondition = (response:any) => {
    if (response) {
      const { id, description } = response;
      this.setState({
        termsData: {
          id, description
        }
      });
    }
  }

  receivePrivacyPolicy = (response:any) => {
    if (response) {
      const { id, description } = response;
      this.setState({
        privacyData: {
          id, description
        }
      });
    }
  }

  receivechangePassword = (response:any) => {
    if (!response.errors) {
      this.setState({
        snackbar: {
          message: response.message, // Corrected typo here
          open: true,
          severity: 'success'
        },
        newPassword:'',
        oldPassword:'',
        confirmPassword:''
      });
    } else {
      this.setState({
        snackbar: {
          message: response.errors,
          open: true,
          severity: 'error'
        }
      });
    }
  };
  
     
}
// Customizable Area End
