import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet } from "react-native";
import { withRouter, Link } from "react-router-dom";
import logoMarque from "../src/panelme.png";
import Dialog from '../../components/src/Dialog.web';
import { Typography, Button,styled } from "@material-ui/core";
import { Divider } from "react-native-elements";
const { baseURL } = require('../../framework/src/config');
import { subscribe } from "./updateHelper";

const TopNav = ({ history }) => {
  const dropdownRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userType, setUserType] = useState('');
  const [userName, setUserName] = useState('');
  const [initials, setInitials] = useState('');
  const [avatar, setAvatar] = useState(null);

  const isLandPage = history?.location?.pathname === '/';
  const Onboardingguide = history?.location?.pathname === '/Onboardingguide';
  const isCreateUserPage = history?.location?.pathname === '/CreateUserAccount'
  const authPages = ['/', '/AuthBlock', '/ForgotPassword', '/ResetPassword', '/SignInUserBlock','/AdminAuth','/CreateUserAccount' ];
  const isAuthPage = authPages.includes(history?.location?.pathname) || history?.location?.pathname?.includes('/ResetPassword');
  const currentPath = history?.location?.pathname;

  useEffect(() => {
    const user = localStorage.getItem('user');
    console.log('username', user)
    const userType = (user && user !== "undefined") ? JSON.parse(user).type : null;
    setUserType(userType)
}, [isLandPage, authPages, isAuthPage])

  useEffect(() => {
    if(userType==='')
      {
        const user =  localStorage.getItem('user');
        const userType =  (user && user !== "undefined") ? JSON.parse(user).type : null;
        setUserType(userType)
      }
    if (window) {
      window.addEventListener("storage", function (event) {
        const token = localStorage.getItem('token');

        if (token && token !== "undefined" && token !== "null") {
          getUser(token);
        }
      });
    }
  }, []);

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleOpenLogoutDialog = () => {
    setVisible(true);
    setIsMenuOpen(false)
  }

  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    setUserName("")
    setVisible(false);
    setIsMenuOpen(false)
    setUserType('')
    history.push("/AuthBlock");
  };

  const handleClose = () => {
    setVisible(false);
  }

  const handleSetting = () => {
    setIsMenuOpen(false)
    history.push("/AdminSettings");
  }

  const handleUserSetting = () => {
    setIsMenuOpen(false)
    history.push("/UserSettings");
  }

  const handleIconClick = () => {
    if (currentPath === "/SurveysWeb") {
     return;
    }
    else if (currentPath ===  "/UserSettings") {
      history.push("/SurveysWeb");
      return;
    }
    else if (currentPath.includes("UserFillSurvey")) {
      history.push("/SurveysWeb");
      return;
    }
    if (isCreateUserPage) {
      return;
    }
    if (userName) {
      if(userType === "user") {
        history.push("/SurveysWeb");
      } else {
        history.push("/AdminSurvey");
      }
    } else {
      history.push("/");
    }
  }

  const getUser = async (token) => {
    console.log('get user call');
    const myHeaders = new Headers();
    myHeaders.append("token", token);
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const user = localStorage.getItem('user');
    const userId = (user && user !== "undefined") ? JSON.parse(user).id : null;
    fetch(`${baseURL}/bx_block_user/users/user_details?id=${userId}`, requestOptions)
      .then(response => response.text())
      .then(user => {
        const userObj = (user && user !== "undefined") ? JSON.parse(user) : null;
        const userName = (userObj?.user?.first_name || "-");
        const initials = (userObj?.user?.first_name?.charAt(0)?.toUpperCase() || "-") + (userObj?.user?.last_name?.charAt(0)?.toUpperCase() || "-");
        setUserType(userObj?.user?.role);
        setUserName(userName);
        setInitials(initials);
        setAvatar(userObj?.user?.avatar);
      })
      .catch(error => console.log('error', error));
  };


  useEffect(() => {
    // Function to handle click events
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click happened outside the dropdown, close it
        setIsMenuOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isLandPage && !isAuthPage) {
      const token = localStorage.getItem('token');

      if (token && token !== "undefined" && token !== "null") {
        getUser(token);
      }
    }
  }, [isLandPage, isAuthPage]);

    useEffect(() => {
        const handleDataUpdate = newData => {
            console.log(1232, newData)
            setUserName(newData.FirstName);
        };
        subscribe('dataUpdated', handleDataUpdate);
        return () => {
            subscribe('dataUpdated', handleDataUpdate);
        };
    }, []);

    const truncateName = (name) => {
      if (name.length > 20) {
        return name.slice(0, 20) + '...';
      }
      return name;
    };
  return (
    <>
    {(!isLandPage && !Onboardingguide) &&
      <View style={styles.main}>
        <header onClick={handleIconClick} style={{ padding: '18px 40px', width: "fit-content", backgroundColor: "#fff" }}>
          <img style={{ height: '34px',cursor:"pointer" }} src={logoMarque} alt="Logo" />
        </header>
        {!isAuthPage &&
          <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 0px' }}>
            <div  style={{ display: 'flex', alignItems: 'center', flex: '1 1 0px' }}>
              {
                !userType ? null : userType === "user" ?
                  <Link to="/SurveysWeb" style={webStyle.link}><Typography style={webStyle.link}>Home</Typography></Link> : (
                  <>
                    <Link to="/AdminSurvey" style={webStyle.link}><Typography style={webStyle.link}>Surveys</Typography></Link>
                    {/* <Link to="/SurveysTemplate" style={webStyle.link}><Typography style={webStyle.link}>Survey Templates</Typography></Link> */}
                    <Link to="/AccountCreation/group" style={webStyle.link}><Typography style={webStyle.link}>Users & Groups</Typography></Link>
                    {/* <Link to="/DashboardWeb" style={webStyle.link}><Typography style={webStyle.link}>Dashboard</Typography></Link> */}
                    <DashboardButton href="/DashboardWeb" disableRipple style={webStyle.link} > <Typography style={webStyle.link}>Dashboard</Typography></DashboardButton>
                    <Link to="/calendar" style={webStyle.link}><Typography style={webStyle.link}>Calendar</Typography></Link>
                  </>
                )
              }
            </div>
            <div ref={dropdownRef} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={webStyle.userName}>{truncateName(userName)}</Typography>
              <div onClick={handleMenuOpen} style={webStyle.profileContainer}>
                {avatar ?
                  <img style={webStyle.profileImg} src={avatar} alt="Selected PNG Image" /> :
                  <Typography style={webStyle.initials}>{initials}</Typography>
                }
              </div>
              { isMenuOpen &&
                <div style={{ position: 'absolute', top: '60px', right: '68px', backgroundColor: '#fff', padding: '12px', borderRadius: '4px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                  {
                    userType === "user" ?
                        <div onClick={handleUserSetting} style={webStyle.link}><Typography style={{paddingBottom:"5px", fontFamily: 'aeonik-regular'}}>Settings</Typography></div> : (
                        <div onClick={handleSetting} style={webStyle.link}><Typography style={{paddingBottom:"5px", fontFamily: 'aeonik-regular'}}>Settings</Typography></div>
                      )
                  }
                  <Divider/>
                  <div onClick={handleOpenLogoutDialog} style={webStyle.link}><Typography style={{paddingTop:"5px", fontFamily: 'aeonik-regular'}}>Logout</Typography></div>
                </div>
              }
            </div>
          </div>
        }
      </View>
    }
      <Dialog style={webStyle.modal} visible={visible}>
        <div style={webStyle.modalBody}>
            <div>
                <p style={webStyle.logoutTitle}>Log Out</p>
            </div>
            <div>
                <p style={webStyle.textquestion}>
                    Are you sure you want to quit the application?
                </p>
            </div>
            <div style={webStyle.buttonWrap}>
                <Button onClick={handleLogoutClick} style={webStyle.primaryBtn}>Log Out</Button>
                <Button onClick={() => handleClose()} style={webStyle.secBtn}>Close</Button>
            </div>
        </div>
      </Dialog>
    </>
  );
};

const DashboardButton=styled(Button)({
  '&:hover':{
    backgroundColor:'transparent',
  },
})

const webStyle  = {
  link: {
    color: 'var(--text, #202020)',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    fontFamily: 'aeonik-bold',
    marginRight: 24,
    textDecoration: 'none',
    cursor: 'pointer',
    textTransform:'none',
  },
  profileContainer: {
    width: '40px',
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ECEFF7',
    position: 'relative',
    marginRight: '40px',
    borderRadius: '50%',
},
  initials: {
    padding: '8px',
    fontSize: '18px',
    color: '#A2AFCD',
    fontFamily: 'aeonik-bold',
    fontWeight: 700,
    fontStyle: 'normal',
    textAlign: 'center',
    lineHeight: 'normal',
    cursor: 'pointer'
},
profileImg: {
  height: '40px',
  width: '40px',
  objectFit: 'cover',
  cursor: 'pointer',
  borderRadius: '50%',
},
userName: {
    color: 'var(--text, #202020)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'aeonik-bold',
    fontWeight: 600,
    lineHeight: 'normal',
    marginRight: '12px',
  },
  modalBody: {
      background: '#FFF',
      padding: '40px'
  },
  buttonWrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
  },
  primaryBtn: {
      padding: '18px 32px',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
      textTransform: 'none',
      fontStyle: 'normal',
      borderRadius: '10px',
      lineHeight: 'normal',
      fontSize: '16px',
      color: 'rgba(255, 255, 255, 1)',
      fontWeight: 700,
      marginTop: '30px',
      flex: '1 1 0px',
      fontFamily: 'aeonik-regular',
  },
  secBtn: {
      alignItems: 'center',
      padding: '18px 32px',
      textTransform: 'none',
      border: 'none',
      justifyContent: 'center',
      fontWeight: 700,
      fontSize: '16px',
      color: '#333',
      background: 'none',
      lineHeight: 'normal',
      fontStyle: 'normal',
      flex: '1 1 0px',
      fontFamily: 'aeonik-regular',
    },
  textquestion: {
      textAlign: 'center',
      fontStyle: 'normal',
      color: '#202020',
      fontWeight: 600,
      fontSize: '18px',
      marginTop: "10px",
      fontFamily: 'aeonik-regular'
  },
  header: {
      color: 'black',
      textAlign: 'center',
      fontSize: '18px',
      fontFamily: 'aeonik-bold',
      fontWeight: 700,
      lineHeight: '20px'
  },
  logoutTitle: {
    color: 'black',
    textAlign: 'center',
    fontSize: '24px',
    fontFamily: 'aeonik-bold',
    fontWeight: 700,
  }
}

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "76px",
    display: "flex",
    alignItems: "center",
    position: "fixed",
    zIndex: 100
  },

});

export default withRouter(TopNav);
