import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './customCalender.css';

function CustomToolbar({ label, setCurrentView, onNavigate, onDateChange }: any) {
    return (
        <div className="toolbar">
            <button onClick={() => onNavigate('TODAY')} className="toolbar-button">
                Today
            </button>
            <button onClick={() => {
                setCurrentView("month")
                onNavigate('PREV')
            }}
                className="toolbar-button">
                ←
            </button>
            <DatePicker
                selected={new Date(label)}
                onChange={(date) => { setCurrentView("month"); onDateChange(date) }}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                className="datepicker-input"
            />
            <button onClick={() => { setCurrentView("month"); onNavigate('NEXT') }} className="toolbar-button">
                →
            </button>

        </div>
    );
}

function CalendarWithDatePicker() {
    const localizer = momentLocalizer(moment);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentView, setCurrentView] = useState<"month" | "week" | "day">("month");

    const eventPropGetter = (event: any) => {
        const backgroundColor = event.status === "Open" ? "#E7F2DA" : "#e6e6fa";
        const borderColor = event.status === "Open" ? "#4caf50" : "#6a5acd";
        return {
            style: {
                backgroundColor,
                border: `1px solid ${borderColor}`,
                color: "#424242",
                borderRadius: "5px",
                padding: "5px",
            },
        };
    };

    const events = [
        {
            title: 'Morning Meeting',
            start: new Date(2025, 0, 25, 9, 0),
            end: new Date(2025, 0, 25, 10, 0),
        },
        {
            title: 'Team Discussion',
            start: new Date(2025, 0, 25, 11, 0),
            end: new Date(2025, 0, 25, 12, 0),
        },
        {
            title: 'Meeting',
            start: new Date(2025, 0, 25, 10, 0),
            end: new Date(2025, 0, 25, 12, 0),
        },
        {
            title: 'Lunch Break',
            start: new Date(2025, 0, 26, 13, 0),
            end: new Date(2025, 0, 26, 14, 0),
        },
    ];

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    const handleSelectSlot = (slotInfo: any) => {
        setSelectedDate(slotInfo.start);
        setCurrentView("day");
    };

    const handleNavigate = (action: string) => {
        if (action === 'TODAY') {
            setSelectedDate(new Date());
        } else if (action === 'PREV') {
            setSelectedDate((prev) => moment(prev).subtract(1, 'month').toDate());
        } else if (action === 'NEXT') {
            setSelectedDate((prev) => moment(prev).add(1, 'month').toDate());
        }
    };

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                view={currentView}
                date={selectedDate}
                onNavigate={(date) => setSelectedDate(date)}
                defaultView="month"
                eventPropGetter={eventPropGetter}
                onSelectSlot={handleSelectSlot}
                selectable={true}
                components={{
                    toolbar: (props) => (
                        <CustomToolbar
                            {...props}
                            label={selectedDate}
                            setCurrentView={setCurrentView}
                            onNavigate={handleNavigate}
                            onDateChange={handleDateChange}
                        />
                    ),
                }}
                style={{
                    height: '100%',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                }}
            />
        </div>
    );
}

export default CalendarWithDatePicker;
