import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { iphone1, onBoarding1, onBoarding2 } from "./assets";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentStep: number;
  skipGuide: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingguideController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      currentStep: 0,
      skipGuide: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
  }

  // Customizabele Area Start
  cards = [
    {
      question: onBoarding1,
      placeholder: "Enter your name here",
      guideTitle: "SURVEY GUIDE",
      guideDescription:
        "Filling in our surveys is easy and quick both in the application and on the web",
    },
    {
      question: onBoarding2,
      placeholder: "Enter your age here",
      guideTitle: "UPDATE SETTINGS",
      guideDescription:
        "Head to Settings, then easily update your details such as industry sector or local authority",
    },
    {
      question: iphone1,
      placeholder: "Enter your favorite color here",
      guideTitle: "DOWNLOAD APP",
      guideDescription:
        "Free to download for iPhone and Android, our app lets you easily fill surveys wherever you are",
    },
  ];

  handleNext = () => {
    if (this.state.currentStep < this.cards.length - 1) {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
      }));
    } else {
      this.setState({
        skipGuide: true,
      });
    }
  };

  handleSkip = () => {
    this.setState({
      skipGuide: true,
    });
  };

  navigateToDashboard = () => {
    this.props.navigation.navigate("SurveysWeb")
  }

  handleDots = (value: number) => {
    this.setState({
      currentStep: value,
    });
  };

  setCookie = (name: any, value: any) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 100);
    const expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  saveSkipGuideToCookie = () => {
    this.setCookie("skipGuide", this.state.skipGuide.toString());
  };

  getCookie = (name: string) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const [key, value] = cookies[i].split("=");
      if (key === name) {
        return value;
      }
    }
    return null;
  };

  async componentDidMount() {
    const skipGuideCookie = this.getCookie("skipGuide");
    if (skipGuideCookie) {
      this.setState({ skipGuide: skipGuideCookie === "true" });
      this.navigateToDashboard();
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    // Customizable Area Start
    if (prevState.skipGuide !== this.state.skipGuide) {
      this.saveSkipGuideToCookie()
      this.navigateToDashboard();
    }
    // Customizable Area End
  }

  // Customizable Area End
}
